@import "styles/colors";
@import "styles/mixin";

.image {
  position: relative;
  width: 290px;
  height: 231.18px;
  margin-right: 44.5px;
}
.wrapper {
  display: flex;
  width: 1024px;
  max-width: 100%;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  padding-top: 101.41px;
}
.title {
  font-size: 40px;
  line-height: 44px;
  font-weight: 600;
  color: $grey-700;
  margin-bottom: 8px;
}
.text {
  font-size: 20px;
  line-height: 22px;
  font-weight: 500;
  color: #868686;
  margin-top: 0;
  margin-bottom: 32px;
}
.button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 231px;
  height: 36px;
  background: $blue;
  color: $white;
  border: none;
  border-radius: 5px;
  font-weight: 500;
}
@include media("within", "sm") {
  .wrapper {
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 0;
    padding: 3rem 3rem;
  }
  .image {
    margin-right: 0;
    margin-bottom: 20px;
  }
  .title {
    font-size: 32px;
    line-height: 34px;
  }
  .text {
    font-size: 16px;
    margin-bottom: 32px;
  }
  .button {
    width: 100%;
  }
  .info {
    text-align: center;
  }
}